import {
  selectIsAuthenticated,
  selectIsCorporateBranch,
  selectIsMarketplaceSeller,
  selectIsEnterpriseParent,
  selectIsMarketplace,
  selectIsCorporateHQ,
  selectMasterShipper,
  selectShippers,
  selectWebsiteLocale,
  selectSubShipper,
  selectViewAsExternalRefValue,
  selectIsEnterprise
} from 'containers/Base/selectors'
import _ from 'lodash'
import moment from 'moment'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ApiHelper, NvApi, nvCall } from '@nv/react-commons/src/Services'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import { searchTypes } from 'containers/ConnectedSearch/redux'
import { dashApi, websiteApi } from 'services/api'
import { mixpanelTrackLogOut, mixpanelIdentifyAndLogAppVersion } from '../../components/Mixpanel/helpers'
import { getLocale } from 'utils/locale'

import {
  fcmCreators,
  orderCreateCreators,
  shipperCreators,
  shipperTypes,
  startupCreators,
  userCreators,
  userTypes
} from './redux'
import { languageCreators } from 'containers/LanguageProvider/redux'
import { DEFAULT_LOCALE, REHYDRATE, ROUTES } from './constants'
import { orderSearchCreators } from 'containers/Base/redux'
import { ISO_DATE_FORMAT } from '@nv/react-commons/src/Constants'

const { selector } = SelectorUtils
const { startupSuccess } = startupCreators

export function * getSettings () {
  // shipper settings
  yield put(ApiHelper.creators.request('OCSettings', dashApi.getSettings, ['order_create']))
  yield put(ApiHelper.creators.request('MasterOCSettings', dashApi.getMasterSettings, ['order_create']))
  yield put(ApiHelper.creators.request('pickupSettings', dashApi.getSettings, ['pickup']))
  yield put(ApiHelper.creators.request('pricing', dashApi.getSettings, ['pricing']))
  yield put(
    ApiHelper.creators.request('integrationSettings', dashApi.getSettings, ['integration'], { clearFirst: true })
  )
  yield put(ApiHelper.creators.request('notificationSettings', dashApi.getSettings, ['notification']))
  yield put(ApiHelper.creators.request('metadata', dashApi.getMetadata))
  yield put(ApiHelper.creators.request('printerSettings', dashApi.getSettings, ['label_printer']))
  yield put(ApiHelper.creators.request('fulfillmentSettings', dashApi.getSettings, ['cross_border']))

  // Dash Alerts, Banners and Campaigns from the website
  const locale = yield select(selectWebsiteLocale())
  yield put(ApiHelper.creators.request('alerts', websiteApi.getDashAlerts, [locale]))
  yield put(ApiHelper.creators.request('banners', websiteApi.getDashBanners, [locale]))
  yield put(ApiHelper.creators.request('campaigns', websiteApi.getDashCampaigns, [locale]))

  // shipper campaigns
  yield put(ApiHelper.creators.request('shipperCampaigns', dashApi.getCampaigns, undefined, { clearFirst: true }))
}

export function * getExternalRefSettings () {
  const user = yield select(selector('global', 'user', 'data')())
  const { currentId } = yield select(selector('global', 'shipper')())
  const externalRef = yield select(selectViewAsExternalRefValue())

  if (user.masterShipper && currentId && user.masterShipper.id !== currentId && externalRef) {
    let shippers = yield select(selectShippers())
    shippers = _.mapValues(_.groupBy(shippers, 'id'), x => x[0])
    if (!shippers[currentId]) {
      shippers[currentId] = yield select(selectSubShipper())
    }
    shippers[currentId].externalRef = externalRef
    yield put(shipperCreators.getShipperSuccess(shippers))
  }
}

export function * startup () {
  const { accessToken, locale } = yield select(selector('global', 'user', 'data')())
  const { currentId } = yield select(selector('global', 'shipper')())
  if (accessToken && currentId) {
    yield call(NvApi.setAuthToken, accessToken)
    yield call(NvApi.setShipper, currentId)
    yield call(updateLocale, { locale })
    yield put(userCreators.getUserRequest())
    yield call(getSettings)
  }
  yield put(startupSuccess())
}

export function * getUser () {
  const getUserResponse = yield nvCall(dashApi.getUser)
  // subShippers is housed under a seperate endpoint
  const isCorporateHq = yield select(selectIsCorporateHQ())
  const isMarketplace = yield select(selectIsMarketplace())
  const isEnterpriseParent = yield select(selectIsEnterpriseParent())
  const isCorporateBranch = yield select(selectIsCorporateBranch())
  const isMarketplaceSeller = yield select(selectIsMarketplaceSeller())
  const isEnterprise = yield select(selectIsEnterprise())
  const externalRef = yield select(selectViewAsExternalRefValue())
  const shipper = yield select(selector('global', 'shipper')())

  const currentId = shipper?.currentId

  if (getUserResponse?.ok) {
    const user = getUserResponse.data
    let getSubShippersResponse
    if (isEnterpriseParent || externalRef) {
      getSubShippersResponse = yield nvCall(dashApi.getSubShippers, user.defaultShipperId)
    }
    let subShippers = []
    if (isCorporateHq || (isCorporateBranch && externalRef)) {
      subShippers = getSubShippersResponse?.data?.data ?? {}
    } else if (isMarketplace || (isMarketplaceSeller && externalRef)) {
      subShippers = getSubShippersResponse?.data?.details ?? {}
    }
    let shippers = _.mapValues(_.groupBy(user.shippers, 'id'), x => x[0])

    if (user.masterShipper && currentId) {
      const countResp = yield nvCall(dashApi.getShippersCount, user.masterShipper.id)
      if (countResp.ok) {
        const count = countResp?.data?.data ?? {}
        yield put(shipperCreators.getSubShippersCount(count))
      }

      if (isCorporateBranch) {
        const corporateBranchResponse = yield nvCall(dashApi.getShipperBranch, user.masterShipper.id, currentId)
        if (corporateBranchResponse.ok) {
          if (!shippers[currentId]) {
            shippers[currentId] = yield select(selectSubShipper())
          }
          shippers[currentId].externalRef = corporateBranchResponse.data.externalRef
        }
      }
    }
    if (!isEnterprise) {
      // linkShippers is housed under a separate endpoint
      const linkShippersResponse = yield nvCall(dashApi.getLinkableShippers)
      if (linkShippersResponse?.ok && linkShippersResponse.data.shippers !== null) {
        yield put(userCreators.getLinkShippers(linkShippersResponse.data.shippers))
      }
    }

    if (subShippers) {
      subShippers = _.mapValues(_.groupBy(subShippers, 'id'), x => x[0])
      // Merge externalRef in subShippers with main shipper object
      shippers = _.merge(shippers, subShippers)
      yield put(shipperCreators.getShipperSuccess(shippers))
    }

    yield put(userCreators.getUserSuccess(_.omit(user, 'shippers')))
    yield call(mixpanelIdentifyAndLogAppVersion)
    yield call(updateLocale, user)
  } else if (_.includes([401, 403], getUserResponse.status)) {
    const isAuthenticated = yield select(selectIsAuthenticated())
    if (isAuthenticated) {
      yield put(userCreators.logout())
    }
    yield put(userCreators.getUserFailure(getUserResponse.data.error))
  }
}

export function * selectShipper (action) {
  const isEnterprise = yield select(selectIsEnterprise())
  const { currentId } = action
  yield put(orderCreateCreators.clear())
  yield call(NvApi.setShipper, currentId)
  if (!isEnterprise) {
    // Needs to set shipper ID first before being authorized to call this API
    const linkShippersResponse = yield nvCall(dashApi.getLinkableShippers)
    if (linkShippersResponse.ok && linkShippersResponse.data.shippers !== null) {
      yield put(userCreators.getLinkShippers(linkShippersResponse.data.shippers))
    }
  }
  yield put(shipperCreators.selectShipperSuccess(currentId))
  yield call(getSettings)
  yield call(getExternalRefSettings)
}

export function * logout () {
  yield put(fcmCreators.unsubscribeFcmRequest())
  yield put(startupCreators.startupSuccess())
  yield call(mixpanelTrackLogOut)
  yield call(NvApi.clean)
}

export function * searchOrder (action) {
  if (action.key !== 'order') {
    return
  }

  const path = window.location.pathname
  yield put(orderSearchCreators.clearFilter())
  yield put(
    orderSearchCreators.saveFilter({
      createdAt: {
        startValue: moment()
          .add(-1, 'years')
          .startOf('day')
          .utc()
          .format(ISO_DATE_FORMAT),
        endValue: moment()
          .endOf('day')
          .utc()
          .format(ISO_DATE_FORMAT)
      }
    })
  )
  if (path === ROUTES.DEBUG) {
    yield put(orderSearchCreators.searchRequest(true))
  }

  if (action.route && path !== action.route) {
    yield call(action.navigate, action.route)
  } else if (!action.route && path !== ROUTES.TRACKING_SEARCH) {
    yield call(action.navigate, ROUTES.TRACKING_SEARCH)
  }
}

export function * updateLocale ({ locale = DEFAULT_LOCALE, showNotification }) {
  yield call(NvApi.setLanguage, locale)
  yield put(languageCreators.changeLocale(getLocale(locale), showNotification))
}

export function * getSubShipper ({ externalRef }) {
  const masterShipper = yield select(selectMasterShipper())
  const isCorporateHQ = yield select(selectIsCorporateHQ())
  const isCorporateBranch = yield select(selectIsCorporateBranch())
  const isMarketPlace = yield select(selectIsMarketplace())
  const isMarketPlaceSeller = yield select(selectIsMarketplaceSeller())
  const isCorporate = isCorporateHQ || isCorporateBranch
  if (_.isEmpty(externalRef)) {
    // return to marketplace shipper
    yield put(shipperCreators.selectShipper(masterShipper?.id))

    // exist 'View As' mode
    yield put(userCreators.getSubShipperSuccess(null))
    yield put(userCreators.getSubShipperFailure(''))
  } else {
    yield call(NvApi.setShipper, masterShipper?.id)
    let response
    if (isMarketPlace || isMarketPlaceSeller) {
      response = yield nvCall(dashApi.getMarketplaceSeller, masterShipper?.id, externalRef)
    } else {
      response = yield nvCall(dashApi.getCorporateBranch, masterShipper?.id, externalRef)
    }

    const { data } = response

    if (data.error) {
      let error = data.error
      try {
        error = JSON.parse(error?.message).error
      } catch (e) {}
      const msg = error?.title ?? 'Not found'
      yield put(userCreators.getSubShipperFailure(msg))
    } else {
      let subShipper
      if (isCorporate) {
        subShipper = data
      } else if (data?.length > 0) {
        subShipper = data[0]
      } else {
        yield put(userCreators.getSubShipperFailure('Not found'))
        return
      }
      subShipper.externalRef = externalRef
      yield put(userCreators.getSubShipperSuccess(subShipper))
      yield put(shipperCreators.selectShipper(subShipper.id))
    }
  }
}

export default function * defaultSaga () {
  yield takeLatest(REHYDRATE, startup)
  yield takeLatest(userTypes.GET_USER_REQUEST, getUser)
  yield takeLatest(userTypes.LOGOUT, logout)
  yield takeLatest(userTypes.UPDATE_LOCALE, updateLocale)
  yield takeLatest(shipperTypes.SELECT_SHIPPER, selectShipper)
  yield takeLatest(searchTypes.SEARCH_REQUEST, searchOrder)
  yield takeLatest(userTypes.GET_SUB_SHIPPER, getSubShipper)
}
