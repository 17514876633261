import React, { useCallback, useContext, useMemo, useState } from 'react'
import { T, Tooltip, Row } from '@nv/react-commons/src/Components'
import { StyledCustomCard } from 'containers/FPLOrderCreate/styles'
import { StyleConfirmdButton } from './styles'
import { useIntl } from 'hooks/common'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { buildB2CBoxRequests, buildB2BBoxRequests } from './utils'
import { batchB2COrders, batchOC } from 'services/api/fplApi'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'containers/Base/constants'
import { isEmpty } from 'lodash'
import { PICKUP_TYPES } from 'containers/PickupType/constants'
import { CCType, OCMethod, SUPPORTED_DOCUMENT_STATUS } from './constants'
import { message } from 'antd'
import { mpTrackConfirmTheOrder } from 'containers/FPLMixpanel/helpers'
import { MP_ORDER_TYPE, PAGE_NAMES } from 'containers/FPLMixpanel/constants'
import { CREATION_METHODS } from 'containers/FPLOrderCreate/constants'

const initialState = {
  batchError: false,
  batchMessage: '',
  isLoading: false
}

const ReviewStep = () => {
  const intl = useIntl()
  const [state, setState] = useState(initialState)
  const { batchError, batchMessage, isLoading } = state
  const { ocState } = useContext(MMCCOrderCreationContext)

  const {
    boxOrders,
    senderAddress,
    pickupType,
    pickupAddress,
    recipientAddress,
    selectedService,
    volume,
    timeSlot,
    ocMethod,
    boxOrdersFromKeyboard,
    commercialFiles,
    deliveryFiles,
    commercialStatus,
    deliveryStatus,
    selectedCCType,
    allOrders,
    invalidOrders
  } = ocState

  const navigate = useNavigate()

  const isFullFilledSubmitData = useMemo(() => {
    const commonCondition = !isEmpty(senderAddress) && !isEmpty(recipientAddress) && !isEmpty(selectedService)

    let boxesLengthCondition = false
    if (ocMethod === OCMethod.KEYBOARD) {
      boxesLengthCondition = boxOrdersFromKeyboard.length > 0
    } else if (ocMethod === OCMethod.UPLOAD) {
      boxesLengthCondition = boxOrders.length > 0
    } else if (ocMethod) {
      boxesLengthCondition = true
    }

    const pickupCondition =
      pickupType !== PICKUP_TYPES.SCHEDULED || (!isEmpty(timeSlot) && !isEmpty(pickupAddress) && !!volume)

    const supportingDocsStatus =
      commercialStatus !== SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL &&
      deliveryStatus !== SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL
    return commonCondition && pickupCondition && boxesLengthCondition && supportingDocsStatus
  }, [
    senderAddress,
    recipientAddress,
    selectedService,
    ocMethod,
    pickupType,
    boxOrdersFromKeyboard.length,
    boxOrders.length,
    timeSlot,
    pickupAddress,
    volume,
    commercialStatus,
    deliveryStatus
  ])

  const handleResponse = useCallback(
    res => {
      if (res.ok) {
        const batchId = res?.data?.data?.batch_id
        navigate(`${ROUTES.FPL_MMCC_ORDER_BATCH}?id=${batchId}`)
      } else {
        let msg = res.data?.error?.message
        if (res.data?.error?.validation_errors) {
          const errorMessages = Object.values(res.data.error.validation_errors).join(', ')
          msg = `${msg}: ${errorMessages}`
        }
        setState(prevState => ({ ...prevState, batchError: true, batchMessage: msg, isLoading: false }))
        message.error(msg, 10)
      }
    },
    [navigate]
  )

  const trackConfirmOrder = (
    totalCreated: number,
    totalNeededActions: number,
    totalOrders: number,
    orderType: string
  ) => {
    const mpData = {
      totalCreated,
      totalNeededActions,
      totalOrders,
      inputMethod: ocMethod ? CREATION_METHODS[ocMethod] : '',
      pageName: PAGE_NAMES[ROUTES.FPL_MMCC_ORDER_CREATION],
      orderType: orderType
    }
    mpTrackConfirmTheOrder(mpData)
  }

  const handleClickConfirm = useCallback(async () => {
    setState(prevState => ({ ...prevState, isLoading: true }))

    if (selectedCCType === CCType.B2B) {
      let totalCreated,
        totalNeededActions,
        totalOrders = 0
      if (ocMethod === OCMethod.KEYBOARD) {
        totalCreated = totalOrders = boxOrdersFromKeyboard.length
      } else {
        totalCreated = boxOrders.length
        totalNeededActions = Object.keys(allOrders).length - boxOrders.length
        totalOrders = Object.keys(allOrders).length
      }

      trackConfirmOrder(totalCreated, totalNeededActions, totalOrders, MP_ORDER_TYPE.MMCC_B2B)
      const payload = buildB2BBoxRequests({
        boxOrders: ocMethod === OCMethod.KEYBOARD ? boxOrdersFromKeyboard : boxOrders,
        senderAddress,
        recipientAddress,
        pickupType,
        pickupAddress,
        volume,
        timeSlot,
        ocMethod,
        selectedService,
        commercialFiles,
        deliveryFiles
      })
      const res = await batchOC(payload)
      handleResponse(res)
    } else {
      trackConfirmOrder(
        boxOrders.length,
        Object.keys(invalidOrders).length,
        Object.keys(invalidOrders).length + boxOrders.length,
        MP_ORDER_TYPE.MMCC_B2C
      )
      const payload = buildB2CBoxRequests({
        boxOrders,
        senderAddress,
        recipientAddress,
        pickupType,
        pickupAddress,
        volume,
        timeSlot,
        ocMethod,
        selectedService,
        commercialFiles,
        deliveryFiles
      })
      const res = await batchB2COrders(payload)
      handleResponse(res)
    }
  }, [
    ocMethod,
    boxOrdersFromKeyboard,
    senderAddress,
    recipientAddress,
    pickupType,
    pickupAddress,
    volume,
    timeSlot,
    selectedService,
    commercialFiles,
    deliveryFiles,
    handleResponse,
    boxOrders,
    allOrders,
    invalidOrders,
    selectedCCType
  ])

  const renderConfirmButton = useCallback(() => {
    if (!isFullFilledSubmitData) {
      return (
        <Tooltip
          title={intl.formatMessage({
            id: 'international_check_and_fill_required_fields'
          })}
        >
          <StyleConfirmdButton
            disabled
            type='primary'
            onClick={handleClickConfirm}
            loading={isLoading}
            data-analyticsid='create4PLOrders'
          >
            <T id='confirm' />
          </StyleConfirmdButton>
        </Tooltip>
      )
    }

    return (
      <StyleConfirmdButton
        type='primary'
        onClick={handleClickConfirm}
        loading={isLoading}
        data-analyticsid='createMMCCOrders'
      >
        <T id='confirm' />
      </StyleConfirmdButton>
    )
  }, [handleClickConfirm, intl, isFullFilledSubmitData, isLoading])

  return (
    <>
      <StyledCustomCard hoverable={false}>
        <Row type='flex' justify='space-between' align='middle'>
          <T id='confirm_order' />
          {renderConfirmButton()}
        </Row>
      </StyledCustomCard>
    </>
  )
}

export { ReviewStep }
