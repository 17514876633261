import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

const { Types, Creators } = createActions(
  {
    estimatePriceRequest: ['data'],
    estimatePriceSuccess: ['data'],
    estimatePriceFailure: ['error']
  },
  { prefix: 'app/OCCompleted/' }
)

const INITIAL_STATE = fromJS({
  data: null,
  loading: false,
  error: null
})

export const ocCompletedTypes = Types
export const ocCompletedCreators = Creators

export default createReducer(INITIAL_STATE, {
  [Types.ESTIMATE_PRICE_REQUEST]: state => state.merge({ loading: true }),
  [Types.ESTIMATE_PRICE_SUCCESS]: (state, { data }) => state.merge({ data, loading: false }),
  [Types.ESTIMATE_PRICE_FAILURE]: (state, { error }) => state.merge({ error, loading: false })
})
