import React, { useEffect } from 'react'
import styled from 'styled-components'
import { NVSelect, NVInput, T } from '@nv/react-commons/src/Components'
import { COUNTRIES } from '@nv/react-commons/src/Constants'

import { OC_SERVICE_TYPES } from 'containers/Base/constants'
import { transformSize } from 'utils/csvMappingFields'
import { Text } from 'components/Text'
import { useFlag } from 'featureHub/useFlag'
import featureKeys from 'featureHub/featureKeys'
import { Colors, media } from 'themes'

import { StyledFormItem } from './styles'
import { InfoTooltip } from './Field'
import { SIZE_DIMENSIONS, FIELDS, SIZE_OPTS } from './constants'
import { CustomDimensions } from './OCForm.CustomDimensions'

const Option = NVSelect.Option

export const EstimatedSize = props => {
  const { form, intl, country, order } = props
  const { getFieldDecorator, setFieldsValue } = form
  const isDocType = order?.serviceType === OC_SERVICE_TYPES.DOCUMENT
  const isSGShipper = country?.toLowerCase() === COUNTRIES.SG
  const isUsingCustomDimensions = order?.useCustomDimensions
  const mergeLiteAndMobileFeature = useFlag(featureKeys.MERGE_LITE_AND_MOBILE_FEATURES)

  // set default size
  useEffect(() => {
    const size = isDocType ? 'S' : order?.size ?? 'S'
    setFieldsValue({ size })
  }, [isDocType, order?.size, setFieldsValue])

  // set default dimensions based on selected size
  useEffect(() => {
    const size = transformSize(order?.size)
    const dimensions = SIZE_DIMENSIONS[size]
    if (mergeLiteAndMobileFeature && dimensions && !isUsingCustomDimensions) {
      setFieldsValue({ ...dimensions })
    }
  }, [order?.size, setFieldsValue, isUsingCustomDimensions, mergeLiteAndMobileFeature])

  const openCustomDimensions = () => {
    setFieldsValue({ useCustomDimensions: true })
  }

  return (
    <Wrapper style={{ '--mb': mergeLiteAndMobileFeature ? '20px' : '8px' } as React.CSSProperties}>
      <FormItemWrapper
        label={
          <span>
            <T id='estimated_size' />
            {isSGShipper && <InfoTooltip tooltipTextId='estimated_size_tooltip' />}
          </span>
        }
        style={{
          marginBottom: '0'
        }}
      >
        {getFieldDecorator(FIELDS.SIZE, {
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'estimated_size_required'
              })
            }
          ]
        })(
          <NVSelect placeholder={<T id='pick_size' />} disabled={isDocType || isUsingCustomDimensions}>
            {SIZE_OPTS.map((x, i) => {
              if (mergeLiteAndMobileFeature) {
                // hide XXL
                if (x === 'XXL') {
                  return null
                }

                // hide XL for countries besides SG
                if (x === 'XL' && country?.toLowerCase() !== COUNTRIES.SG) {
                  return null
                }
              } else {
                if (x === 'XXL' && country?.toLowerCase() == COUNTRIES.SG) {
                  return (
                    <Option key={i} value={`${i}`} disabled>
                      {x}
                    </Option>
                  )
                }
              }

              return (
                <Option key={i} value={`${i}`}>
                  {x}
                </Option>
              )
            })}
          </NVSelect>
        )}

        {mergeLiteAndMobileFeature && !isUsingCustomDimensions && (
          <OpenButtonWrapper>
            <button type='button' onClick={openCustomDimensions}>
              <CustomiseDimentionsText id='customise_dimensions' />
            </button>
          </OpenButtonWrapper>
        )}
      </FormItemWrapper>

      {/* hidden field used to show/hide custom dimensions */}
      {mergeLiteAndMobileFeature && (
        <>
          {getFieldDecorator('useCustomDimensions', {
            initialValue: false
          })(<NVInput type='hidden' />)}
        </>
      )}

      {mergeLiteAndMobileFeature && <CustomDimensions {...props} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: var(--mb, 8px);
`

const FormItemWrapper = styled(StyledFormItem)`
  margin-bottom: 0;
`

const OpenButtonWrapper = styled.div`
  line-height: normal;
  margin-top: -2px;
`

const CustomiseDimentionsText = styled(Text)`
  font-size: 14px;
  color: ${Colors.akiraRed3};
  line-height: normal;
  text-align: left;

  ${media.min.tablet`
    font-size: 12px;
  `}
`
