import { GRANULAR_STATUS_TEXT } from '@nv/react-commons/src/Constants'
import { SelectorUtils } from '@nv/react-commons/src/Utils'
import { nvCall } from '@nv/react-commons/src/Services/ApiHelper/saga'
import { OrderStatusFilter } from 'components/OrderStatusFilter'
import {
  orderSearchCreators,
  orderSearchTypes,
  orderCancelationCreators,
  orderCancelationTypes
} from 'containers/Base/redux'
import { searchCreators } from 'containers/ConnectedSearch/redux'
import _ from 'lodash'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import dashApi from 'services/api/dashApi'
import { makeSelectLocale } from 'containers/LanguageProvider/selectors'
import { mixpanelSearchAdvancedOrder, mixpanelSearchOrder } from '../../components/Mixpanel/helpers'
import { selectMarketplaceShipperViewFilter } from '../Base/selectors'
import { selectIsB2BBundleSupported } from 'containers/OrderCreate/selectors'
import { TRACKING_ORDER_TYPE } from './Tracking.constants'
import { ORDER_DETAIL_SERVICE_TYPE } from 'containers/Base/constants'
const { selector } = SelectorUtils

const MAX_ORDER_SEARCH_SIZE = 10000 // Maximum size per request

export function groupChecklistByOrderStatus (checkedList) {
  return _.groupBy(checkedList, function (x) {
    if (_.includes(OrderStatusFilter.COD_STATUS, x)) {
      return 'codCheckedList'
    } else {
      return 'granularStatusCheckedList'
    }
  })
}

export const hasFetchedAllOrders = globalOrder => {
  const orders = globalOrder?.data?.orders
  const total = globalOrder?.data?.total
  return orders && total && orders.length === total
}

function * fetchAllOrders (searchReq, locale) {
  let overallResp = {
    data: {
      searchData: [],
      total: 0
    },
    ok: true
  }
  let isCompleted = false
  let requestFrom = 0

  do {
    requestFrom = overallResp.data.searchData.length

    const resp = yield nvCall(dashApi.searchOrder, locale, searchReq, requestFrom, MAX_ORDER_SEARCH_SIZE)

    if (!resp.ok || !resp.data) {
      return resp
    }

    const data = resp.data
    if (data) {
      overallResp = {
        ...overallResp,
        data: {
          searchData: overallResp.data.searchData.concat(data.searchData),
          total: data.total
        }
      }

      // Update the number of records retrieved
      if (overallResp.data.searchData.length === overallResp.data.total) {
        isCompleted = true
      }
    }
  } while (!isCompleted)

  return overallResp
}

export function * advancedSearch (action) {
  const { reset = true, size = 100, fetchAll = false } = action

  const searchResults = yield select(selector('global', 'orderSearch', 'results')())
  let allFetched = hasFetchedAllOrders(searchResults)

  if (reset) {
    allFetched = false
    yield put(orderSearchCreators.clearResults())
  }
  if (allFetched) {
    return
  }

  yield put(orderSearchCreators.searchRequestInProgress(reset))
  const {
    orderId,
    bulkUploadId,
    createdAt,
    completedAt,
    fileUpload,
    granularStatus,
    stampId,
    orderType
  } = yield select(selector('global', 'orderSearch', 'filter')())
  const { endValue, startValue } = completedAt ?? createdAt
  const fieldValue = completedAt ? 'completed_at' : 'created_at'
  const searchProps = yield select(selector('search')())
  const isB2BBundleSupported = yield select(selectIsB2BBundleSupported())
  let searchFields = ['tracking_id', 'to_contact', 'to_name']
  if (isB2BBundleSupported) {
    searchFields.push('mps_tracking_id')
  }

  const searchField =
    searchProps && searchProps.key === 'order' && searchProps.data
      ? {
          fields: searchFields,
          matchType: 'full_text',
          value: searchProps.data
        }
      : null
  const searchRange = startValue &&
    endValue && {
      endTime: endValue,
      field: fieldValue,
      startTime: startValue
    }
  const requiredFields = []
  const searchFilters = []
  let searchType = null
  if (granularStatus && !_.isEmpty(granularStatus.checkedList)) {
    const { checkedList } = granularStatus
    const checkedListByStatus = groupChecklistByOrderStatus(checkedList)
    const { codCheckedList, granularStatusCheckedList } = checkedListByStatus
    if (codCheckedList?.length === 1) {
      requiredFields.push({
        field: 'cod_id',
        value: codCheckedList[0] === OrderStatusFilter.COD
      })
    }
    if (!_.isEmpty(granularStatusCheckedList)) {
      searchFilters.push({
        field: 'granular_status',
        values: granularStatusCheckedList.map(x => GRANULAR_STATUS_TEXT[x])
      })
    }
  }

  if (orderId) {
    searchFilters.push({
      field: 'id',
      values: [orderId]
    })
  }
  if (bulkUploadId) {
    searchType = 'batch_id'
    searchFilters.push({
      field: 'batch_id',
      values: [bulkUploadId]
    })
  }
  if (stampId) {
    searchType = 'stamp_id'
    searchFilters.push({
      field: 'stamp_id',
      values: [stampId]
    })
  }
  if (orderType && orderType !== TRACKING_ORDER_TYPE.ALL) {
    let serviceTypeValues = Object.values(ORDER_DETAIL_SERVICE_TYPE).filter(serviceType =>
      orderType === TRACKING_ORDER_TYPE.DOMESTIC
        ? !serviceType.includes(ORDER_DETAIL_SERVICE_TYPE.INTERNATIONAL)
        : serviceType.includes(ORDER_DETAIL_SERVICE_TYPE.INTERNATIONAL)
    )
    searchFilters.push({
      field: 'service_type',
      values: serviceTypeValues
    })
  }
  if (fileUpload && !_.isEmpty(fileUpload.trackingIds)) {
    searchType = 'file_upload'
    searchFilters.push({
      field: 'tracking_id',
      values: fileUpload && fileUpload.trackingIds
    })
  }

  const ordersViewFilter = yield select(selectMarketplaceShipperViewFilter())
  if (ordersViewFilter) {
    searchFilters.push(ordersViewFilter)
  }

  const locale = yield select(makeSelectLocale())
  const searchReq = { requiredFields, searchField, searchFilters, searchRange }
  const existingOrders = yield select(selector('global', 'orderSearch', 'results', 'data', 'orders')())
  let from = existingOrders ? existingOrders.length : 0

  let resp
  if (fetchAll) {
    resp = yield fetchAllOrders(searchReq, locale)
  } else {
    resp = yield nvCall(dashApi.searchOrder, locale, searchReq, from, _.min([size, MAX_ORDER_SEARCH_SIZE]))
  }

  if (resp.ok && resp.data) {
    yield put(orderSearchCreators.searchRequestSuccess(resp.data.searchData, resp.data.total))
    if (searchProps?.key === 'order') {
      yield call(mixpanelSearchOrder, searchField?.value?.length, resp.data.total)
    } else {
      yield call(mixpanelSearchAdvancedOrder, searchFilters, searchRange, resp.data.total, searchType)
    }
  } else {
    yield put(orderSearchCreators.searchRequestFailure({ problem: resp?.problem, ...(resp?.data?.error ?? {}) }))
  }
}

function * saveFilterAndClearOthers (action) {
  yield put(searchCreators.clear())
  yield put(orderSearchCreators.clearFilter())
  yield put(orderSearchCreators.saveFilter(action.data))
}

function * cancelOrders (action) {
  const { data } = action
  const responses = yield all(data.map(orderId => call(dashApi.cancelOrder, orderId)))
  const success = []
  const failure = []
  data.forEach((orderId, index) => (responses[index]?.ok ? success.push(orderId) : failure.push(orderId)))
  yield put(orderCancelationCreators.success(success, failure))
  yield put(orderSearchCreators.filterResults(success))
}

export default function * defaultSaga () {
  yield takeLatest(orderSearchTypes.SEARCH_REQUEST, advancedSearch)
  yield takeLatest(orderSearchTypes.SAVE_FILTER_AND_CLEAR_OTHERS, saveFilterAndClearOthers)
  yield takeLatest(orderCancelationTypes.REQUEST, cancelOrders)
}
