import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { OC_SERVICE_TYPES } from 'containers/Base/constants'

export const getMaxDimensions = (country = '') => {
  switch (country && country.toLowerCase()) {
    case COUNTRIES.SG:
      return 300
    case COUNTRIES.TH:
      return 200
    case COUNTRIES.VN:
      return 240
    case COUNTRIES.PH:
      return 150
    default:
      return 180
  }
}

export const getDefaultDimensions = (country = '') => {
  switch (country && country.toLowerCase()) {
    case COUNTRIES.MY:
    case COUNTRIES.TH:
    case COUNTRIES.VN:
    case COUNTRIES.PH:
    case COUNTRIES.ID:
      return [10, 25, 10]
    default:
      return []
  }
}

export const hasDimensionSize = (country = '') => {
  switch (country && country.toLowerCase()) {
    case COUNTRIES.SG:
    case COUNTRIES.MA:
      return false
    default:
      return true
  }
}

export const isB2BBundleOrder = (serviceType, isB2BBundleSupported) => {
  const { B2B_BUNDLE, CORPORATE_B2B_BUNDLE } = OC_SERVICE_TYPES

  return Boolean(isB2BBundleSupported && (serviceType === B2B_BUNDLE || serviceType === CORPORATE_B2B_BUNDLE))
}
