import React, { useMemo } from 'react'

import { T, Tooltip } from '@nv/react-commons/src/Components'
import { Colors } from 'themes'
import { isEmpty } from 'lodash'
import { InfoTooltip } from 'containers/FPLOrderRequestList/Fields'
import { Hspace } from 'components/Hspace'
import { useIntl } from 'hooks/common'
import {
  InlineBlock,
  InvalidToolTipWrapper,
  SeperatedLine,
  StyledHeaderTable,
  StyledRightIcon,
  StyledRowTable,
  StyledStatusText,
  StyledTableContent
} from 'containers/FPLOrderCreate/styles'
import { CustomLink, TextCol, TextWrapper } from './styles'
import { faCheckCircle } from '@fa-pro-solid/faCheckCircle'
import { faExclamationCircle } from '@fa-pro-solid/faExclamationCircle'
import { faList } from '@fa-pro-regular/faList'
import { faUpload } from '@fa-pro-regular/faUpload'
import { faFile } from '@fa-pro-regular/faFile'
import { CCType } from './constants'
import { isB2CBoxes } from './utils'

const UploadResult = ({
  invalidOrders,
  validOrders,
  fileName,
  onVisibleViewDetail,
  ccType,
  onReUpload,
  numberOfValidItemsInValidOrders,
  numberOfValidItemsInInvalidOrders,
  numberOfInvalidItemsInInvalidOrders
}) => {
  const intl = useIntl()

  const numberOfValidOrders = useMemo(() => {
    return validOrders.length
  }, [validOrders])

  const numberOfInvalidOrders = useMemo(() => {
    return invalidOrders.length
  }, [invalidOrders])

  const instructionTooltip = useMemo(() => {
    let viewDetailText = 'international_view_detail_items_error_message'

    if (numberOfInvalidOrders === 0) {
      viewDetailText = 'international_view_disabled_view_error_instruction_for_invalid_boxes'
    }

    return viewDetailText
  }, [numberOfInvalidOrders])

  const getUnit = (value, isItemisedUnit = false) => {
    const unitText = intl.formatMessage({ id: isItemisedUnit ? 'item' : 'box' })
    if (value > 1) {
      return intl.formatMessage({ id: isItemisedUnit ? 'items' : 'boxes' })
    }
    return unitText.toLowerCase()
  }

  const renderB2CResultTable = () => {
    return (
      <StyledTableContent>
        <StyledRowTable>
          <TextCol first>
            <T id='international_valid_boxes' />
          </TextCol>
          <TextWrapper>
            <TextCol>{`${numberOfValidOrders} ${getUnit(numberOfValidOrders)}`}</TextCol>
            <TextCol color={Colors.silver}>
              {`(${numberOfValidItemsInValidOrders} ${getUnit(numberOfValidItemsInValidOrders, true)})`}
            </TextCol>
          </TextWrapper>
          {isEmpty(invalidOrders) && (
            <>
              <Hspace width={16} />
              <StyledStatusText color={Colors.mediumGreen} success>
                <StyledRightIcon icon={faCheckCircle} color={Colors.mediumGreen} />
                <T id='international_all_boxex_are_valid' />
              </StyledStatusText>
            </>
          )}
        </StyledRowTable>
        <StyledRowTable>
          <TextCol first color={!isEmpty(invalidOrders) && Colors.cherryRed}>
            <T id='international_invalid_boxex' />
          </TextCol>
          <TextWrapper>
            <TextCol color={!isEmpty(invalidOrders) && Colors.cherryRed}>
              {`${numberOfInvalidOrders} ${getUnit(numberOfInvalidOrders)}`}
            </TextCol>
          </TextWrapper>
          {isEmpty(validOrders) && (
            <>
              <Hspace width={16} />
              <StyledStatusText color={Colors.cherryRed}>
                <StyledRightIcon icon={faExclamationCircle} color={Colors.cherryRed} />
                <T id='international_all_boxes' />
              </StyledStatusText>
            </>
          )}
        </StyledRowTable>
        <StyledRowTable>
          <TextCol first>
            <T id='international_total_boxes' />
          </TextCol>
          <TextWrapper>
            <TextCol>
              {`${numberOfValidOrders + numberOfInvalidOrders} ${getUnit(numberOfValidOrders + numberOfInvalidOrders)}`}
            </TextCol>
            <TextCol color={Colors.silver}>
              {`(${numberOfValidItemsInInvalidOrders +
                numberOfInvalidItemsInInvalidOrders +
                numberOfValidItemsInValidOrders} ${getUnit(
                numberOfValidItemsInInvalidOrders +
                  numberOfInvalidItemsInInvalidOrders +
                  numberOfValidItemsInValidOrders,
                true
              )})`}
            </TextCol>
          </TextWrapper>
        </StyledRowTable>
      </StyledTableContent>
    )
  }

  const renderB2BResultTable = () => {
    return (
      <StyledTableContent>
        <StyledRowTable>
          <TextCol first>
            <T id='international_valid_boxes' />
          </TextCol>
          <TextWrapper width={100}>
            <TextCol>{`${numberOfValidOrders} ${getUnit(numberOfValidOrders)}`}</TextCol>
          </TextWrapper>
          {isEmpty(invalidOrders) && (
            <>
              <StyledStatusText color={Colors.mediumGreen} success>
                <StyledRightIcon icon={faCheckCircle} color={Colors.mediumGreen} />
                <T id='international_all_boxex_are_valid' />
              </StyledStatusText>
            </>
          )}
        </StyledRowTable>
        <StyledRowTable>
          <TextCol first color={!isEmpty(invalidOrders) && Colors.cherryRed}>
            <T id='international_invalid_boxex' />
          </TextCol>
          <TextWrapper width={100}>
            <TextCol color={!isEmpty(invalidOrders) && Colors.cherryRed}>
              {`${numberOfInvalidOrders} ${getUnit(numberOfInvalidOrders)}`}
            </TextCol>
          </TextWrapper>
          {isEmpty(validOrders) && (
            <>
              <StyledStatusText color={Colors.cherryRed}>
                <StyledRightIcon icon={faExclamationCircle} color={Colors.cherryRed} />
                <T id='international_all_boxes' />
              </StyledStatusText>
            </>
          )}
        </StyledRowTable>
        <StyledRowTable>
          <TextCol first>
            <T id='international_total_boxes' />
          </TextCol>
          <TextWrapper>
            <TextCol>
              {`${numberOfValidOrders + numberOfInvalidOrders} ${getUnit(numberOfValidOrders + numberOfInvalidOrders)}`}
            </TextCol>
          </TextWrapper>
        </StyledRowTable>
      </StyledTableContent>
    )
  }

  return (
    <>
      <StyledHeaderTable>
        <InlineBlock>
          <StyledRightIcon icon={faFile} />
          {fileName}
        </InlineBlock>
        <InlineBlock>
          <Tooltip title={intl.formatMessage({ id: instructionTooltip })}>
            <CustomLink
              onClick={onVisibleViewDetail}
              lineHeight={0}
              disabled={isEmpty(invalidOrders)}
              leftIcon={faList}
              to='#'
            >
              <T id='international_view_detail_to_correct' />
            </CustomLink>
          </Tooltip>
          <Hspace width={16} />
          <SeperatedLine />
          <Hspace width={16} />
          <Tooltip title={intl.formatMessage({ id: 'international_replace_file_message' })}>
            <CustomLink onClick={onReUpload} lineHeight={0} leftIcon={faUpload} to='#'>
              <T id='international_replace_file' />
            </CustomLink>
          </Tooltip>
        </InlineBlock>
      </StyledHeaderTable>
      {isB2CBoxes(ccType) ? renderB2CResultTable() : renderB2BResultTable()}
    </>
  )
}

export { UploadResult }
