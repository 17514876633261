/**
 *
 * OrderTypeModal
 *
 */

import { faArrowSquareLeft } from '@fa-pro-light/faArrowSquareLeft'
import { faBox } from '@fa-pro-light/faBox'
import { faFileAlt } from '@fa-pro-light/faFileAlt'
import { faEnvelopeOpen } from '@fa-pro-light/faEnvelopeOpen'
import { T } from '@nv/react-commons/src/Components'
import Colors from '@nv/react-commons/src/Themes/Colors'
import { OptionCard } from 'components/OptionCard'
import { RouteModal } from 'components/RouteModal'
import { SalesChannelsLink } from 'components/SalesChannelsLink'
import { Text } from 'components/Text'
import { selectCountry, selectShipper } from 'containers/Base/selectors'
import {
  selectIsInternationalOCSupported,
  selectIsNinjaPackOCSupported,
  selectIsCorporateAWBOCSupported,
  selectIsParcelOCSupported,
  selectIsReturnOCSupported,
  selectIsDocumentAvailable,
  selectIsCorporateDocumentAvailable,
  selectIsB2BBundleSupported
} from 'containers/OrderCreate/selectors'
import { ORDER_TYPE_MODAL_PATH } from 'containers/OrderTypeModal/constants'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import { ROUTES } from '../Base/constants'
import { mixpanelTrackStartOrderCreation, mixpanelTrackNinjaPackOrderCreationEvents } from 'components/Mixpanel/helpers'
import { ORDER_CREATE_TYPES } from './constants'
import { withLocation } from '../RouterHOCs'
import { NinjaPackLink } from 'components/NinjaPackLink'
import { getNinjaPacksLink } from 'utils/getNinjaPackLink'
import { trackEvent } from 'utils/GoogleAnalytics/GAEventTracker'
import { CREATE_NINJA_PACK_ORDER, CREATE_REGULAR_ORDER, CREATE_RETURN_ORDER } from 'utils/GoogleAnalytics/events'
import { getModalPath } from 'utils/getModalPath'
import { CreateInternationalOrderButton } from 'containers/FPLOrderCreate/CreateInternationalOrderButton'

const StyledModal = styled(RouteModal)`
  && {
    .ant-modal-content {
      border-radius: 10px;
      > div {
        background-color: unset;
      }
    }

    .ant-modal-title {
      border-radius: 10px;
    }
  }
`

const StyledText = styled(Text)`
  color: ${Colors.pinkishGrey};
`

export const Wrapper = styled(({ isLink, children, onLinkClick, ...rest }) =>
  isLink ? (
    <Link onClick={onLinkClick} {...rest}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
)``

export class OrderTypeModal extends React.Component {
  renderRegularOCType = () => {
    const {
      isDocumentTypeAvailable,
      isCorporateDocumentTypeAvailable,
      isB2BBundleSupported,
      isParcelOCSupported,
      country
    } = this.props
    const isRegularOCSupported = isParcelOCSupported || isDocumentTypeAvailable || isB2BBundleSupported

    if (!country) {
      return null
    }
    return (
      <Wrapper
        to={ROUTES.OC_1}
        isLink={isRegularOCSupported}
        onLinkClick={() => mixpanelTrackStartOrderCreation(ORDER_CREATE_TYPES.REGULAR)}
      >
        <OptionCard
          icon={faEnvelopeOpen}
          label='create_a_regular_order'
          description={
            <StyledText>
              {isDocumentTypeAvailable || isCorporateDocumentTypeAvailable ? (
                <T id='create_regular_order_with_document_description' />
              ) : isRegularOCSupported ? (
                <T id='create_regular_order_description' />
              ) : (
                <span>
                  <T id='create_regular_order_description' />
                  &nbsp;
                  <T id='contact_us_at' />
                  &nbsp;
                  <SalesChannelsLink country={country} />
                  &nbsp;
                  <T id='to_enable_this_option' />
                </span>
              )}
            </StyledText>
          }
          onClick={() => trackEvent(CREATE_REGULAR_ORDER, country)}
          disabled={!isRegularOCSupported}
        />
      </Wrapper>
    )
  }

  renderNinjaPacksOCType = () => {
    const { country, shipper } = this.props
    const shipperId = shipper?.id
    const { trackNPCreationStarted } = mixpanelTrackNinjaPackOrderCreationEvents()
    return (
      <Link to={`${ROUTES.OC_PACKS}/1`} onClick={trackNPCreationStarted}>
        <OptionCard
          icon={faBox}
          label='create_a_ninja_pack_order'
          description={
            <>
              <StyledText>
                <T id='create_ninja_pack_order_description' />
              </StyledText>
              {getNinjaPacksLink(country, shipperId) && (
                <NinjaPackLink packLink={getNinjaPacksLink(country, shipperId)} />
              )}
            </>
          }
          onClick={() => trackEvent(CREATE_NINJA_PACK_ORDER, country)}
        />
      </Link>
    )
  }

  renderCorporateManualAWBType = () => (
    <Link to={`${ROUTES.ORDER_CORPORATE_MANUAL_AWB}/1`}>
      <OptionCard
        icon={faFileAlt}
        label='create_a_corporate_manual_awb_order'
        description='create_a_corporate_manual_awb_order_description'
      />
    </Link>
  )

  renderReturnOCType = () => (
    <Wrapper
      to={`${ROUTES.ORDER_RETURN}/1`}
      isLink
      onLinkClick={() => mixpanelTrackStartOrderCreation(ORDER_CREATE_TYPES.RETURN)}
    >
      <OptionCard
        icon={faArrowSquareLeft}
        label='create_a_return_order'
        description='create_return_order_description'
        onClick={() => trackEvent(CREATE_RETURN_ORDER, this.props.country)}
      />
    </Wrapper>
  )

  render () {
    const {
      isNinjaPackOCSupported,
      isCorporateAWBOCSupported,
      isReturnOCSupported,
      shipper,
      location,
      isInternationalOCSupported
    } = this.props
    return (
      <StyledModal
        path={getModalPath(location, ORDER_TYPE_MODAL_PATH)}
        title={<T id='choose_order_type' />}
        {...this.props}
      >
        {this.renderRegularOCType()}
        {isInternationalOCSupported && <CreateInternationalOrderButton shipperId={shipper.id} />}
        {isNinjaPackOCSupported && this.renderNinjaPacksOCType()}
        {isCorporateAWBOCSupported && this.renderCorporateManualAWBType()}
        {isReturnOCSupported && this.renderReturnOCType()}
      </StyledModal>
    )
  }
}

OrderTypeModal.propTypes = {
  isParcelOCSupported: PropTypes.bool.isRequired,
  isInternationalOCSupported: PropTypes.bool.isRequired,
  isNinjaPackOCSupported: PropTypes.bool.isRequired,
  isCorporateAWBOCSupported: PropTypes.bool.isRequired,
  isReturnOCSupported: PropTypes.bool.isRequired,
  isB2BBundleSupported: PropTypes.bool,
  isDocumentTypeAvailable: PropTypes.bool,
  isCorporateDocumentTypeAvailable: PropTypes.bool,
  country: PropTypes.string.isRequired,
  shipper: PropTypes.object.isRequired,
  intl: PropTypes.object,
  location: PropTypes.object
}

OrderTypeModal.defaultProps = {
  isParcelOCSupported: false,
  isInternationalOCSupported: false,
  isNinjaPackOCSupported: false,
  isCorporateAWBOCSupported: false,
  isReturnOCSupported: false
}

const mapStateToProps = createStructuredSelector({
  isParcelOCSupported: selectIsParcelOCSupported(),
  isInternationalOCSupported: selectIsInternationalOCSupported(),
  isNinjaPackOCSupported: selectIsNinjaPackOCSupported(),
  isCorporateAWBOCSupported: selectIsCorporateAWBOCSupported(),
  isReturnOCSupported: selectIsReturnOCSupported(),
  isB2BBundleSupported: selectIsB2BBundleSupported(),
  isDocumentTypeAvailable: selectIsDocumentAvailable(),
  isCorporateDocumentTypeAvailable: selectIsCorporateDocumentAvailable(),
  country: selectCountry(),
  shipper: selectShipper()
})

const withConnect = connect(mapStateToProps, null)

export default compose(withLocation, injectIntl, withConnect)(OrderTypeModal)
