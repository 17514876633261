import moment from 'moment'
import { SALES_CHANNEL } from '../enums/saleschannel'

const isPaymentMethodIncluded = (item, isPrepaid) =>
  item.dashEligibilityPaymentMethods?.value.some(paymentMethod =>
    isPrepaid ? paymentMethod.codename === 'prepaid' : paymentMethod.codename === 'postpaid'
  )

const isAccountTypeIncluded = (item, shipperType) =>
  item.dashEligibilityAccountTypes?.value.some(accountType => accountType.codename === shipperType?.toLowerCase())

const isBeforeExpirationDate = item => moment(Date.now()).isBefore(item?.dashEligibilityExpirationDate?.value, 'day')

const doesMatchRequirement = (criteria, requirement) => {
  if (criteria) {
    const { codename } = criteria.value[0]
    return (codename === 'yes' && requirement) || (codename === 'no' && !requirement) || codename === 'not_applicable'
  } else {
    // consider this criteria is passed
    return true
  }
}

const isSaleChannelIncluded = (item, salesChannel) => {
  let salesChannelCodeName = ''

  if (salesChannel === SALES_CHANNEL.SelfServe) {
    salesChannelCodeName = 'ss'
  }

  if (salesChannel === SALES_CHANNEL.FieldSales) {
    salesChannelCodeName = 'fs'
  }

  if (salesChannelCodeName === '') {
    return false
  }

  return item.dashEligibilitySalesChannels?.value.some(
    itemSalesChannel => itemSalesChannel.codename === salesChannelCodeName
  )
}
/**
 * Determine the first eligible item to show
 * Corresponds with "Dash Eligibility" content snippets in Kontent CMS
 *
 * @param {array} items Array of items competing to be shown
 * @param {object} attributes the attributes of the current shipper
 * @returns the first item in the array that is eligible to be shown
 */
export const getEligibleItemToShow = (items, { isLoyaltyEnabled, isPrepaid, shipperType, salesChannel } = {}) =>
  items?.find(
    item =>
      isBeforeExpirationDate(item) &&
      doesMatchRequirement(item.isLoyaltyEnabled, isLoyaltyEnabled) &&
      (item.dashEligibilitySalesChannels?.value?.length
        ? isSaleChannelIncluded(item, salesChannel)
        : isPaymentMethodIncluded(item, isPrepaid) && isAccountTypeIncluded(item, shipperType))
  )

/**
 * Determine all eligible items to show
 * Corresponds with "Dash Eligibility" content snippets in Kontent CMS
 *
 * @param {array} items Array of items competing to be shown
 * @param {object} attributes the attributes of the current shipper
 * @returns the first item in the array that is eligible to be shown
 */
export const getAllEligibleItemsToShow = (items, { isLoyaltyEnabled, isPrepaid, shipperType, salesChannel } = {}) =>
  items?.filter(
    item =>
      isBeforeExpirationDate(item) &&
      doesMatchRequirement(item.isLoyaltyEnabled, isLoyaltyEnabled) &&
      (item.dashEligibilitySalesChannels?.value?.length
        ? isSaleChannelIncluded(item, salesChannel)
        : isPaymentMethodIncluded(item, isPrepaid) && isAccountTypeIncluded(item, shipperType))
  ) ?? []
