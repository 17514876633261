import React from 'react'
import styled from 'styled-components'
import { Text } from 'components/Text'
import { media, Colors, Fonts } from 'themes'
import { Button } from 'components/Button'
import { Col } from '@nv/react-commons/src/Components'

export const StyledText = styled(Text)`
  && {
    font-size: 15px;
  }
`
export const StyledContainer = styled.div`
  ${media.min.tablet`
      border-left: ${Colors.nvPriRed} solid 8px;
    `};
  .ant-card-body {
    ${media.min.tablet`
        padding: 24px 24px 24px 0px;
      `};
    ${media.max.desktop`
        padding-right: 24px;
      `};
  }
  border-radius: 5px;
  border-top: none;
  border-right: none;
  border-bottom: none;
  color: ${Colors.greyishBrown};
  ${media.max.tablet`margin-top: 48px;`};
`
export const StyledImg = styled.img`
  ${media.max.tablet`
    position: absolute;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
  `};
  ${media.min.tablet`
    margin-right: 16px;
  `};
`
export const StyledGreetingContainer = styled.div`
  ${media.max.tablet`
    flex: 1;
  `};
`
export const StyledGreetingCol = styled(Col)`
  && {
    font-size: 24px;
    ${Fonts.style.bold};
    color: ${Colors.greyishBrown};
    ${media.max.tablet`
      margin-top: 30px;
      margin-bottom: 8px;
      text-align: center;
    `};
  }
`
export const TextCol = styled(Col)`
  && {
    font-size: 15px;
    color: ${Colors.warmGrey};
    ${media.max.tablet`
      margin-bottom: 21px;
      text-align: center;
    `};
  }
`
export const StyledButton = styled(Button)`
  && {
    width: 100%;
    div {
      top: 25px !important;
      left: 0 !important;
    }
  }
`

export const StyledCol = styled(({ textAlign, ...rest }) => <Col {...rest} />)`
  && {
    text-align: ${props => props.textAlign};
  }
`

export const StyledDisabledButton = styled(StyledButton)`
  color: ${Colors.disabled};
  background-color: #d0d4d9;
  border-color: #d0d4d9;
  font-weight: 500;
  cursor: not-allowed;
`
