import { ERROR_CODE_MAPPING, RULE_TYPES } from 'containers/FPLOrderCreate/constants'
import { FLOAT_NUMBER_REGEX, NUMBER_REGEX, PHONE_REGEX } from 'containers/FPLOrderCreate/dataUtils'
import { MMCC_KEYS } from './constants'

const LENGTH_0 = 0
const LENGTH_255 = 255
const LENGTH_1000 = 1000

type MMCCRules = {
  [key: string]: any
}

export const MMCC_RULES: MMCCRules = {
  [MMCC_KEYS.BOX_ID]: {
    required: true,
    rules: [{ type: RULE_TYPES.MAX_LENGTH, validator: LENGTH_255 }]
  },
  [MMCC_KEYS.BOX_WEIGHT]: {
    required: false,
    rules: [
      { type: RULE_TYPES.MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.MAX, validator: LENGTH_1000 },
      { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.BOX_LENGTH]: {
    required: false,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.MAX, validator: LENGTH_1000 },
      { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.BOX_HEIGHT]: {
    required: false,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.MAX, validator: LENGTH_1000 },
      { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.BOX_WIDTH]: {
    required: false,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.MAX, validator: LENGTH_1000 },
      { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.GOODS_DESCRIPTION]: {
    required: true,
    rules: [{ type: RULE_TYPES.EQUAL_MAX_LENGTH, validator: LENGTH_255 }]
  },
  [MMCC_KEYS.NO_OF_PARCELS]: {
    required: true,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.PARCEL_TRACKING_ID]: {
    required: true,
    rules: [{ type: RULE_TYPES.EQUAL_MAX_LENGTH, validator: LENGTH_255 }]
  },
  [MMCC_KEYS.TO_NAME]: {
    required: true,
    rules: [
      { type: RULE_TYPES.MIN_LENGTH, validator: LENGTH_0 },
      { type: RULE_TYPES.MAX_LENGTH, validator: LENGTH_255 }
    ]
  },
  [MMCC_KEYS.TO_ADDRESS]: {
    required: true,
    rules: [
      { type: RULE_TYPES.MIN_LENGTH, validator: LENGTH_0 },
      { type: RULE_TYPES.EQUAL_MAX_LENGTH, validator: LENGTH_255 }
    ]
  },
  [MMCC_KEYS.TO_CONTACT_NUMBER]: {
    required: true,
    rules: [{ type: RULE_TYPES.REGEX, validator: PHONE_REGEX }]
  },
  [MMCC_KEYS.ITEM_QUANTITY]: {
    required: true,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.ITEM_DESCRIPTION]: {
    required: true,
    rules: [
      { type: RULE_TYPES.MIN_LENGTH, validator: LENGTH_0 },
      { type: RULE_TYPES.EQUAL_MAX_LENGTH, validator: LENGTH_1000 }
    ]
  },
  [MMCC_KEYS.ITEM_VALUE]: {
    required: true,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.QUANTITY]: {
    required: true,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.GOODS_VALUE]: {
    required: true,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
    ]
  }
}

export const ERRORS_TRANSLATION_KEYS = {
  [MMCC_KEYS.BOX_ID]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_shipper_box_id_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_invalid_shipper_box_id'
  },
  [MMCC_KEYS.BOX_WEIGHT]: {
    [ERROR_CODE_MAPPING.INVALID]: 'international_box_weight_is_invalid'
  },
  [MMCC_KEYS.BOX_LENGTH]: {
    [ERROR_CODE_MAPPING.INVALID]: 'international_invalid_box_length'
  },
  [MMCC_KEYS.BOX_HEIGHT]: {
    [ERROR_CODE_MAPPING.INVALID]: 'international_invalid_box_height'
  },
  [MMCC_KEYS.BOX_WIDTH]: {
    [ERROR_CODE_MAPPING.INVALID]: 'international_invalid_box_width'
  },
  [MMCC_KEYS.GOODS_DESCRIPTION]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_goods_description_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_goods_description_is_invalid'
  },
  [MMCC_KEYS.NO_OF_PARCELS]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_no_of_parcels_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_no_of_parcels_is_invalid'
  },
  [MMCC_KEYS.PARCEL_TRACKING_ID]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_parcel_tracking_id_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_parcel_tracking_id_invalid_character_limitation'
  },
  [MMCC_KEYS.TO_NAME]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_to_name_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_to_name_is_invalid'
  },
  [MMCC_KEYS.TO_ADDRESS]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_to_address_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_to_address_is_invalid'
  },
  [MMCC_KEYS.TO_CONTACT_NUMBER]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_to_contact_number_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_to_contact_number_is_invalid'
  },
  [MMCC_KEYS.ITEM_QUANTITY]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_item_quantity_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_item_quantity_is_invalid'
  },
  [MMCC_KEYS.ITEM_DESCRIPTION]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_item_description_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_item_description_is_invalid'
  },
  [MMCC_KEYS.ITEM_VALUE]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_item_value_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_item_value_is_invalid'
  },
  [MMCC_KEYS.QUANTITY]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_quantity_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_possitive_quantity'
  },
  [MMCC_KEYS.GOODS_VALUE]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_goods_value_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_goods_value_is_invalid'
  }
}

export const COLUMN_TRUNCATION_MAP = {
  [MMCC_KEYS.TO_ADDRESS]: LENGTH_255,
  [MMCC_KEYS.GOODS_DESCRIPTION]: LENGTH_255,
  [MMCC_KEYS.ITEM_DESCRIPTION]: LENGTH_1000
}
