import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { Row, T, message, Tooltip } from '@nv/react-commons/src/Components'
import { faGlobe } from '@fa-pro-light/faGlobe'
import { getPartnerDetail } from 'services/api/fplApi'
import {
  StyledOptionCard,
  StyledGreyText,
  StyledTextBlock,
  OrderTypeLabel,
  StyledVerticalBar,
  StyledLinkWrapper,
  StyledTag,
  StyledMenu,
  StyledMenuItem
} from './styles'
import CircleIcon from 'components/CircleIcon'
import { mpTrackCreatedOrder } from 'containers/FPLMixpanel/helpers'
import { CREATION_SOURCE, PAGE_NAMES } from 'containers/FPLMixpanel/constants'
import { ROUTES } from 'containers/Base/constants'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'hooks/common'
import { API_RESPONSE_STATUS } from 'containers/FPLRegistration/constants'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { selectCountry, selectIsSS } from 'containers/Base/selectors'
import { connect } from 'react-redux'
import { Text } from 'components/Text'
import SubMenu from 'antd/lib/menu/SubMenu'
import Config from 'configs'
import { ORDER_CREATION_TYPE } from 'containers/FPLHome/HomeCard'

const CardWithTooltip = ({ isDisable, children, onClickLink }) => {
  return isDisable ? (
    <Tooltip title={<T id='international_unavailable_for_lite_account' />} placement='bottom'>
      <div>{children}</div>
    </Tooltip>
  ) : (
    <StyledLinkWrapper onClick={onClickLink}>{children}</StyledLinkWrapper>
  )
}

export const CreateInternationalOrderOptionV2 = ({ shipperId, isSelfServe, country }) => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const intl = useIntl()
  const enableMMCCB2BOrderCreation = Config.FPL_ENABLE_MMCC_B2B_ORDER_CREATION
    ? JSON.parse(Config.FPL_ENABLE_MMCC_B2B_ORDER_CREATION)
    : undefined

  const isSelfServeShipper = useMemo(() => {
    if (!isSelfServe) return false

    const selfServeCountries = Config.FPL_AVAILABLE_SELF_SERVE_COUNTRIES?.split(/,\s*/) || []
    return !selfServeCountries.includes(country) && isSelfServe
  }, [country, isSelfServe])

  const getPartner = async () => {
    setIsLoading(true)
    const response = await getPartnerDetail(shipperId)
    if (!response) return null
    return {
      partnerInfo: response.ok ? response.data.data : null,
      status: response.status
    }
  }

  const trackCreateOrder = (pageName, orderType) => {
    mpTrackCreatedOrder({ pageName, source: CREATION_SOURCE.DASH, orderType })
  }

  const navigateToE2EOrder = async () => {
    const partnerRes = await getPartner()
    if (!partnerRes) {
      message.error(intl.formatMessage({ id: 'error' }))
    } else {
      let pagePath = null
      switch (partnerRes.status) {
        case API_RESPONSE_STATUS.NOT_FOUND:
          pagePath = ROUTES.FPL_SERVICE_REGISTRATION
          break
        case API_RESPONSE_STATUS.SUCCESS:
          pagePath = ROUTES.FPL_OC
          break
      }
      if (pagePath) {
        navigate(pagePath)
        trackCreateOrder(PAGE_NAMES[pagePath], ORDER_CREATION_TYPE.E2E)
      } else {
        message.error(intl.formatMessage({ id: 'error' }))
      }
    }
    setIsLoading(false)
  }

  const navigateToMMCCOrder = () => {
    navigate(ROUTES.FPL_MMCC_ORDER_CREATION)
    trackCreateOrder(PAGE_NAMES[ROUTES.FPL_MMCC_ORDER_CREATION], ORDER_CREATION_TYPE.MMCC)
  }

  const renderCardOption = () => {
    return (
      <StyledOptionCard title={isLoading ? <StyledVerticalBar /> : null} disabled={isSelfServeShipper}>
        <Row type='flex' align='middle'>
          <CircleIcon icon={faGlobe} size='big' disabled={isSelfServeShipper} />
          <StyledTextBlock>
            <StyledTag disabled={isSelfServeShipper}>
              <T id='international_new_feature_tag' />
            </StyledTag>
            <OrderTypeLabel id='create_international_order' disabled={isSelfServeShipper} />
            <StyledGreyText>
              <T id='create_international_order_description' />
            </StyledGreyText>
          </StyledTextBlock>
        </Row>
      </StyledOptionCard>
    )
  }

  const renderCardContentWithoutMMCCOption = () => {
    return (
      <CardWithTooltip isDisable={isSelfServeShipper} onClickLink={navigateToE2EOrder}>
        {renderCardOption()}
      </CardWithTooltip>
    )
  }

  const renderCardContentWithMMCCOption = () => {
    return (
      <StyledMenu>
        <SubMenu
          key='international'
          title={<CardWithTooltip isDisable={isSelfServeShipper}>{renderCardOption()}</CardWithTooltip>}
        >
          <StyledMenuItem key='create_e2e_order' onClick={navigateToE2EOrder}>
            <Text id='create_e2e_order' />
          </StyledMenuItem>
          <StyledMenuItem key='create_mmcc_order' onClick={navigateToMMCCOrder}>
            <Text id='create_mmcc_order' />
          </StyledMenuItem>
        </SubMenu>
      </StyledMenu>
    )
  }

  if (enableMMCCB2BOrderCreation && !isSelfServeShipper) {
    return renderCardContentWithMMCCOption()
  }
  return (
    <CardWithTooltip isDisable={isSelfServeShipper} onClickLink={navigateToE2EOrder}>
      {renderCardContentWithoutMMCCOption()}
    </CardWithTooltip>
  )
}

CreateInternationalOrderOptionV2.propTypes = {
  shipperId: PropTypes.number
}

const mapStateToProps = createStructuredSelector({
  isSelfServe: selectIsSS(),
  country: selectCountry()
})

const withConnect = connect(mapStateToProps, null)

const CreateInternationalOrderOption = compose(withConnect)(CreateInternationalOrderOptionV2)

export { CreateInternationalOrderOption as CreateInternationalOrderButtonV2 }
