import { Colors as NVColors } from '@nv/react-commons/src/Themes'
import { WheelsColors } from './WheelsColors'

const ProjectColors = {
  blueGrey: '#8a9096',
  cherryRed: '#e50239',
  darkGrey: '#212125',
  greyishBrownTwo: '#4a4a4a',
  lightGreyBlue: '#b5b9bd',
  lightPeriwinkle: '#e1e3e6',
  maize: 'rgba(255, 212, 59, 0.7)',
  mediumGreen: '#37b24d',
  overlayDark: 'rgba(0, 0, 0, 0.54)',
  sepia: '#8b572a',
  dangerRed: '#fa002c',
  /**
   * new designer, new color
   */
  newBorder: '#cfcfcf',
  tableHeader: '#E2E6E9',
  /**
   * Chrono-diali
   */
  shipGrey: '#414042',
  cerulean: '#009adf',
  /**
   * Grab
   */
  shamrock: '#00af41',

  disabled: '#aaaeb3',

  /**
   * Payments
   */
  positiveBalance: '#02ca81',
  negativeBalance: '#ff7878',
  balanceBg: '#f2f5f7',
  balanceTxtLight: '#8f8f8f',
  balanceTxtBold: '#4c4c4c',
  balanceBgDark: '#DDDDDD',

  /**
   * International color
   */
  bordeaux: '#6E061A',
  coldTurkey: '#c4aaaf',
  lightGrey: '#F2F2F2',
  darkGreyBlue: '#5C6166',
  lightGreyRed: '#CCCCCC',
  lightGreyBalance: '#B3B3B3',
  lightCherryRed: '#FFDEE4',
  balanceGreyLight: '#F2F2F2',
  balanceRedLight: '#ffe6e8',

  /**
   * Akira
   */
  akiraRed2: '#9A191F',
  akiraRed3: '#C31424',
  akiraGrey7: '#E5E5E5'
}

// ProjectColors overrides Brand Colors
const Colors = { ...NVColors, ...WheelsColors, ...ProjectColors }

export default Colors
