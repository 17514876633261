import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

export const { Types, Creators } = createActions(
  {
    getOrderHistory: [],
    getApiOrderHistory: ['reset', 'batchOffset'],
    setApiOrderHistory: ['reset', 'batch'],
    setApiOrderTotal: ['total'],
    setApiOrderLoading: ['loading'],
    setApiOrderError: ['error'],
    getAllOrdersHistory: ['reset', 'batchOffset'],
    setAllOrdersHistory: ['reset', 'batch'],
    setAllOrdersTotal: ['total'],
    setAllOrdersLoading: ['loading'],
    setAllOrdersError: ['error']
  },
  { prefix: 'OrderHistory/' }
)

const INITIAL_API_ORDER_STATE = {
  history: [],
  total: 0,
  loading: false,
  error: null
}
const INITIAL_STATE = fromJS({
  ...INITIAL_API_ORDER_STATE
})

const setApiOrderTotal = (state, { total }) => state.set('total', total)
const setApiOrderHistory = (state, { reset, batch }) => {
  if (reset) {
    return state.set('history', batch)
  }
  return state.update('history', prevHistory => [...prevHistory, ...batch])
}
const setApiOrderLoading = (state, { loading }) => state.set('loading', loading)
const setApiOrderError = (state, { error }) => state.set('error', error)

export const orderHistoryReducer = createReducer(INITIAL_STATE, {
  [Types.SET_API_ORDER_HISTORY]: setApiOrderHistory,
  [Types.SET_API_ORDER_TOTAL]: setApiOrderTotal,
  [Types.SET_API_ORDER_LOADING]: setApiOrderLoading,
  [Types.SET_API_ORDER_ERROR]: setApiOrderError
})

const setAllOrdersTotal = (state, { total }) => state.set('total', total)
const setAllOrdersHistory = (state, { reset, batch }) => {
  if (reset) {
    return state.set('history', batch)
  }
  return state.update('history', prevHistory => [...prevHistory, ...batch])
}
const setAllOrdersLoading = (state, { loading }) => state.set('loading', loading)
const setAllOrdersError = (state, { error }) => state.set('error', error)

export const allOrdersHistoryReducer = createReducer(INITIAL_STATE, {
  [Types.SET_ALL_ORDERS_HISTORY]: setAllOrdersHistory,
  [Types.SET_ALL_ORDERS_TOTAL]: setAllOrdersTotal,
  [Types.SET_ALL_ORDERS_LOADING]: setAllOrdersLoading,
  [Types.SET_ALL_ORDERS_ERROR]: setAllOrdersError
})
